@import url('https://fonts.googleapis.com/css2?family=IM+Fell+Great+Primer&display=swap');



@keyframes bgcolor {
  0% {
      background-color: black;
      border: 5px  solid black;
      color: white;
  }

  100% {
      background-color: white;
      border: 5px  solid black;
      color: black;
  }
}

@keyframes fadeIn {
  0% {
      opacity: 0;
      
    }
   
    100% {
      opacity: 1;
      
    }
}


body {
  margin: 0;
}
* {
  font-family: 'IM Fell Great Primer', serif;
}

h1 {
  font-size: 20px;
}

.name {
  font-size: 32px;
  margin: 0;
}

.previous {
  font-size: 20px;
  margin: 0;
}

.text {
  margin: 0;
  font-size: 18px;
}

.block {
  width: 90%;
}

.year {
  font-size: 20px;
  font-weight: 500;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", 
  Helvetica, Arial, "Lucida Grande", sans-serif;
}

a {
  color: black;
}
.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 80vh;
}

.pageContainer {
  width: auto;
  max-width: 1000px;
  margin: 0 auto;
}
.footer {
  
  
  
  width: auto;
  text-align: center;
  margin: 0 auto;
  
}


.calendly-inline-widget
{
  width: 1000px !important;
  height: 660px !important;
  overflow-y: hidden;
}

.flex-container {
  margin-top: 0em;
  display: flex;

  align-content: flex-start;

  background-color: transparent;
  flex-wrap: wrap;
  width: 80%;
  margin-bottom: 1em;
}

.item {
  margin-top: 1em;
  width: 33.33%;
  background-color: transparent;
  color: black;
  
}

.websiteImage {
  width: 90%;
  border-radius: 1.5rem;
  border: 5px  solid rgba(0, 0, 0, 1);
}

h5 {
  width: 95%;
  font-weight: normal;
}

button {
  color: white;
  background-color: black;
  border: 5px  solid rgba(0, 0, 0, 1);
  font-size: 1em;
  height: 10em;
  width: 10em;
  border-radius: 50%;
  
  
}

button:hover {
    -webkit-animation: bgcolor 2s;
  animation: bgcolor 2s;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
  background-color: white;
      border: 5px  solid black;
      color: black;

}

@media only screen and (min-width: 300px) and (max-width: 800px)
{

  .block {
  width: 70%;
}

  .flex-container {
    width: 90%;
  }

  a {
    font-size: .9em;
  }

  .item {
    width: 50%;
  }
  .pageContainer {
    width: 100%;
  }

  .center-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 70vh;
  }

  .calendly-inline-widget
{
  margin-top: 20px;
  width: 320px !important;
  height: 800px !important;
  overflow-y: scroll !important;
  border: none;
    border-radius: 2px;
    box-shadow: 0 0 0 2px black;
 
}

.calendly-inline-widget iframe, .calendly-badge-widget iframe, .calendly-overlay iframe {
  height: 1000px !important;
}
._1hXr-rAcJ_.UQ2N0YpIs6 {
  min-height: none !important;
}

}


@media only screen and (min-width: 0px) and (max-width: 299px)
{

  .block {
  width: 80%;
}
  .pageContainer {
    width: 100%;
  }

  .center-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 70vh;
  }

  .calendly-inline-widget
{
  min-width: none !important;
  margin-top: 20px;
  width: 250px !important;
  height: 800px !important;
  overflow-y: scroll !important;
  border: none;
    border-radius: 2px;
    box-shadow: 0 0 0 2px black;
 
}

.calendly-inline-widget iframe, .calendly-badge-widget iframe, .calendly-overlay iframe {
  height: 1000px !important;
}
._1hXr-rAcJ_.UQ2N0YpIs6 {
  min-height: none !important;
}

.flex-container {
  width: 90%;
}

a {
  font-size: .9em;
}

.item {
  width: 50%;
}

}
